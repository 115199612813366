
import Vue from "vue";
export default Vue.extend({
  name: "TimePicker",
  props: {
    value: { type: String, default: null },
    label: String,
    prependIcon: String,
    prependInnerIcon: String,
    prependIconNone: Boolean,
    appendIcon: String,
    appendOuterIcon: String,
    outlined: Boolean,
    disabled: Boolean,
    clearable: Boolean,
    width: { type: [Number, String] },
    maxWidth: { type: String },
    rules: Array
  },
  data() {
    return {
      time: "",
      show: false
    };
  },
  computed: {
    Width: function(): string {
      return this.width
        ? `width:${
            (this.width as string).match("^.*px$")
              ? this.width
              : this.width + "px"
          };`
        : "";
    },
    MaxWidth: function(): string {
      return this.maxWidth
        ? `max-width:${
            (this.maxWidth as string).match("^.*px$")
              ? this.maxWidth
              : this.maxWidth + "px"
          };`
        : "";
    }
  },
  watch: {
    time: function(v) {
      this.$emit("input", v);
    }
  },
  methods: {
    onClearClicked() {
      console.log("onClearClicked", this.value);
      this.time = "";
      console.log("onClearClicked", this.value, this.time);
    }
  },
  created() {
    if (this.value) this.time = this.value;
  }
});
